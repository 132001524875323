<template>
  <div>
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="p-0 mb-2">
                <div class="w-full mb-2">
                    <label class="vs-input--label">Cliente:</label>
                    <v-select  
                        label="nome"                                                                 
                        :options="listaClientes"  
                        :filter="pesquisarCliente"
                        v-model="clienteSelecionado" 
                        class="w-full vs-input--label">
                        <template v-slot:option="option">                        
                            <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                        </template>
                    </v-select>  
                </div>
                <label class="vs-input--label">Email:</label>
                <vs-input class="w-1/2 ml-1" v-model="emailAvisoFinanceiro" maxlength="45" />
                <label class="vs-input--label">Numero WhatsApp:</label>
                <vs-input class="w-1/2 ml-1" v-model="numeroWhatsAppInformado" maxlength="11" />
                <vs-button class="ml-1 mt-2" @click="enviar" color="success" :disabled="!clienteSelecionado || !emailAvisoFinanceiro">ENVIAR</vs-button>            
            </vx-card>
        </vs-col>
    </vs-row> 
            
  </div>
</template>

<script>
import axios from "@/axios.js"

export default {   
    created() {
     
    },
    data() {
        return {                        
            listaClientes: [],             
            clienteSelecionado: null,                    
            emailAvisoFinanceiro: null,
            numeroWhatsAppInformado: null,
            errorsValidacao: [],   
            publicPath: process.env.BASE_URL
        }
    },
    methods: {              
        enviar() {
            let self_ = this;
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja enviar as faturas em aberto do cliente  '
                 + self_.clienteSelecionado.nome + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.enviarRequest
            });
        },
        enviarRequest() {
            this.$vs.loading();  
            const params = new URLSearchParams();                               
            params.append('cpfCNPJInformado', this.clienteSelecionado.CNPJCPF);              
            params.append("emailInformado", this.emailAvisoFinanceiro);
            params.append("numeroWhatsAppInformado", this.numeroWhatsAppInformado);          
            params.append('nomeFavorecido', this.clienteSelecionado.nome);                
            params.append('method', 'ENVIAR_FATURAS_EM_ABERTO');
            
            axios.post("/ClientesRest", params, {              
                    responseEncoding: 'iso88591' } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {                   
                    this.$vs.dialog({
                        type: 'alert', color: 'warning', title: 'Atenção', text: response.data.mensagem, acceptText: 'OK'
                    });
                }
            })
            .catch(() => { 
                this.$vs.loading.close();                                
            })  
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },        
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;                             
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        
    },
    mounted() {               
        this.fetchListaClientes();
    }
   
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 200px);
      position: relative;      
    } 
</style>