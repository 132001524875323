import { render, staticRenderFns } from "./RelatorioModulo.vue?vue&type=template&id=75c93e50&scoped=true&"
import script from "./RelatorioModulo.vue?vue&type=script&lang=js&"
export * from "./RelatorioModulo.vue?vue&type=script&lang=js&"
import style0 from "./RelatorioModulo.vue?vue&type=style&index=0&id=75c93e50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75c93e50",
  null
  
)

export default component.exports