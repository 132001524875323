export default [
  {
    url: "/home",
    name: "Home",
    slug: "menu-home",
    icon: "HomeIcon",
  },
  {
    url: null,
    name: "Cadastros",
    slug: "menu-cadastro",
    icon: "FileIcon",
    submenu: [
      {
        url: '/cadastro/acessorios-modulos/pesquisar',
        name: "Acessórios Módulos",
        slug: "menu-cadastro-acessorio-modulos",
      },
      {
        url: "/cadastro/empresas/pesquisar",
        name: "Central de Rastreamento",
        slug: "menu-cadastro-empresas",
      },
      {
        url: "/cadastro/clientes/pesquisar",
        name: "Clientes",
        slug: "menu-cadastro-clientes",
      },     
      {
        url: "/cadastro/marca-veiculo/pesquisar",
        name: "Marca Veículos",
        slug: "menu-cadastro-marca-veiculo",
      },
      {
        url: "/cadastro/tipo-mensagem-teclado/pesquisar",
        name: "Mensagem Teclado",
        slug: "menu-cadastro-tipo-mensagem-teclado",
      },
      {
        url: "/cadastro/modelo-veiculo/pesquisar",
        name: "Modelo Veículos",
        slug: "menu-cadastro-modelo-veiculo",
      },      
      {
        url: "/cadastro/modulos/pesquisar",
        name: "Modulos",
        slug: "menu-cadastro-modulos",
      },
      {
        url: "/cadastro/ocorrencia-veiculo/pesquisar",
        name: "Ocorrências",
        slug: "menu-cadastro-ocorrencia",
      },
      {
        url: "/cadastro/ordem-servico/pesquisar",
        name: "Ordens de Serviço",
        slug: "menu-cadastro-ordem-servico",
      },
      {
        url: "/cadastro/pre-cadastro/pesquisar",
        name: "Pré-Cadastro",
        slug: "menu-cadastro-pre-cadastro",
      },
      {
        url: "/cadastro/simcard/pesquisar",
        name: "Simcard",
        slug: "menu-cadastro-simcard",
      },
      {
        url: "/cadastro/tecnicos/pesquisar",
        name: "Técnicos",
        slug: "menu-cadastro-tecnicos",
      },
      {
        url: "/cadastro/usuarios/pesquisar",
        name: "Usuários",
        slug: "menu-cadastro-usuarios",
      },      
    ]
  },
  {
    url: null,
    name: "Backend",
    slug: "menu-backend",
    icon: "ActivityIcon",
    submenu: [
      {
        url: '/backend/alteracao-veiculo',        
        name: "Alterar Veículo",
        slug: "menu-backend-alteracao-veiculo",
      },
      {
        url: '/backend/alteracao-veiculo-em-lote',        
        name: "Alterar Veículo Lote",
        slug: "menu-backend-alteracao-veiculo-em-lote",
      },
      {
        url: '/backend/lote-comando-modulo/pesquisar',        
        name: 'Comandos em Lote',
        slug: 'menu-backend-envio-comando-em-massa',
      },
      {
        url: '/backend/envio-2a-via',        
        name: 'Enviar Fatura',
        slug: 'menu-backend-envio-2a-via',
      },         
      {
        url: '/backend/impressao-formulario-pedido-manual',        
        name: 'Formulário Manual',
        slug: 'menu-backend-impressao-formulario-pedido-manual',
      },
      {
        url: '/backend/impressao-formulario-pedido-manual-pre-cadastro',        
        name: 'Formulário Manual Pré',
        slug: 'menu-backend-impressao-formulario-pedido-manual-pre-cadastro',
      },
      {
        url: '/backend/impressao-formulario-pedido',        
        name: 'Formulário de Pedido',
        slug: 'menu-backend-impressao-formulario-pedido',
      },
      {
        url: '/backend/impressao-contrato',        
        name: 'Impressão Contrato',
        slug: 'menu-backend-impressao-contrato',
      },
      {
        url: '/backend/listar-rastreamento',        
        name: 'Listar Rastreamento',
        slug: 'menu-backend-listar-rastreamento',
      },
      {
        url: '/backend/listar-telemetria',        
        name: 'Listar Telemetria',
        slug: 'menu-backend-listar-telemetria',
      },         
      {
        url: '/backend/localizacao',        
        name: "Localização",
        slug: "menu-backend-localizacao-veiculos",
      },
      {
        url: '/backend/monitoramento',        
        name: "Monitoramento",
        slug: "menu-backend-monitoramento-veiculos",
      },
      {
        url: '/backend/tabela-precos-cliente',        
        name: 'Tabela Preços Cliente',
        slug: 'menu-backend-tabela-precos-cliente',
      },
      {
        url: '/backend/tabela-precos-central',        
        name: 'Tabela Preços Padrão',
        slug: 'menu-backend-tabela-precos-central',
      }, 
      {
        url: '/backend/mancha-urbana',        
        name: 'Mapa de Municípios',
        slug: 'menu-backend-mancha-urbana',
      },           
             
    ]
  },
  {
    url: null,
    name: "Ativação",
    slug: "menu-ativacao",
    icon: "AirplayIcon",
    submenu: [
      {
        url: '/backend/ativacao',        
        name: "Ativação",
        slug: "menu-backend-ativacao-ativacao",      
      },
      {
        url: '/backend/ativacao-backup',        
        name: "Ativação Bkp",
        slug: "menu-backend-ativacao-ativacao-bkp",      
      },
      {
        url: '/backend/ativacao-acessorio',        
        name: "Ativação Acessório",
        slug: "menu-backend-ativacao-ativacao-acessorio",      
      },
      {
        url: '/backend/ativacao-historico',        
        name: "Ativação Historico",
        slug: "menu-backend-ativacao-ativacao-historico",      
      },
      {
        url: '/backend/desativacao',        
        name: "Desativação",
        slug: "menu-backend-ativacao-desativacao",
      },
      {
        url: '/backend/desativacao-backup',        
        name: "Desativação Bkp",
        slug: "menu-backend-ativacao-desativacao-backup",
      },
      {
        url: '/backend/desativacao-acessorios',        
        name: "Desativação Acessórios",
        slug: "menu-backend-ativacao-desativacao-acessorios",
      },
      {
        url: '/backend/substituicao',        
        name: "Substituição",
        slug: "menu-backend-ativacao-substituicao",      
      },  
      {
        url: '/backend/substituicao-simcard',        
        name: "Substituição Simcard",
        slug: "menu-backend-ativacao-substituicao-simcard",      
      },     
    ]
  },
  {
    url: null,
    name: "Relatórios",
    slug: "menu-relatorios",
    icon: "MenuIcon",
    submenu: [        
      {
        url: '/relatorios/relatorio-cliente-backup',        
        name: "Cliente ",
        slug: "menu-relatorios-relatorio-cliente-backup",
      },      
      {
        url: '/relatorios/relatorio-modulos-com-problema',        
        name: "Módulos Desatualizados",
        slug: "menu-relatorios-relatorio-modulos-com-problema",
      },      
      {
        url: '/relatorios/relatorio-modulos',        
        name: "Módulos",
        slug: "menu-relatorios-relatorio-modulos",
      },      
      {
        url: '/relatorios/relatorio-valor-mensalidade',        
        name: "Valor Mensalidade",
        slug: "menu-relatorios-relatorio-valor-mensalidade",
      },  
      {
        url: '/relatorios/relatorio-veiculos',        
        name: "Veículos",
        slug: "menu-relatorios-relatorio-veiculos",
      },     
      {
        url: '/relatorios/relatorio-veiculos-inativos',        
        name: "Veículos Inativos",
        slug: "menu-relatorios-relatorio-veiculos-inativos",
      },  
      {
        url: '/relatorios/relatorio-senhas-bloqueio',        
        name: "Senhas de Bloqueio",
        slug: "menu-relatorios-relatorio-senhas-bloqueio",
      },   
      {
        url: '/relatorios/relatorio-comissao',        
        name: "Comissão OS",
        slug: "menu-relatorios-relatorio-comissao",
      },     
    ]
  },
  {
    url: null,
    name: "Configuração",
    slug: "menu-configuracao",
    icon: "SettingsIcon",
    submenu: [
      {
        url: '/configuracao/envio-comando',        
        name: "Envio Comandos",
        slug: "menu-configuracao-envio-comando",
      }, 
      {
        url: '/configuracao/status-rastreador',        
        name: "Status Módulo",
        slug: "menu-configuracao-status-rastreador",
      },      
    ]
  },
  {
    url: "/",
    name: "Sair",        
    icon: "LogOutIcon",
    slug: "menu-sair",
    i18n: "Sair"
  },
]
