<template>
  <div>    
    <vs-row v-if="tabelaPreco && !tabelaPreco.hasOwnProperty('id')" class="mb-2">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">           
            <vs-alert class="mt-4 mr-4" color="warning" title="Atenção"> Tabela de preço inexistente. </vs-alert>                          
        </vs-col>
    </vs-row>   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <small v-if="tabelaPreco && tabelaPreco.hasOwnProperty('id') && tabelaPreco.nomeUsuarioUltimaAtualizacao"> Última Atualização: <span class="text-success">{{tabelaPreco.dataUltimaAtualizacao}} </span></small>
    <small v-if="tabelaPreco && tabelaPreco.hasOwnProperty('id') && tabelaPreco.nomeUsuarioUltimaAtualizacao"> / Usuário: <span class="text-success">{{tabelaPreco.nomeUsuarioUltimaAtualizacao}} </span></small>
    <vs-row vs-justify="center" v-if="exibirTabela">
        <vs-col vs-justify="flex-start" vs-align="center" vs-w="4">  
            <vx-card class="tabelaComScroll">
                <vs-row>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="12">           
                        <h5> Instalação</h5>
                        <label class="vs-input--label">Instalação Rastreador GSM</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.instalacao" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Rastreador GSM com Bloqueio</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.instalacaoRastreadorBloqueio" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Rastreador GSM com Bloqueio e Pânico</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.instalacaoRastreadorBloqueioPanico" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Satelite </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.instalacaoSAT" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Rastreador GSM + Satelite </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.instalacaoGSMeSAT" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Desinstalação </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.desinstalacao" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Bloqueio </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.bloqueio" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Sensor RPM </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.sensorRpm" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Sensor Odômetro </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.odometro" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Sensor Tanque </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.sensorTanque" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Sensor Simples </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.sensorSimples" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Botão de Pânico </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.botaoPanico" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Indentificador Motorista </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.instalacaoIdentificadorMotorista" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Sensor RS232 </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.instalacaoRS232" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Telemetria </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.instalacaoTelemetria" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Travas </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.instalacaoTravas" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Instalação Atuador Simples</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.instalacaoAtuadorSimples" :options="configurarNumerico2Digitos"></vue-autonumeric>
                    </vs-col>
                </vs-row>
            </vx-card>
        </vs-col>
        <vs-col vs-justify="flex-start" vs-align="center" vs-w="4">  
            <vx-card class="tabelaComScroll">
                <vs-row>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="12">   
                        <h5> Mensalidade</h5>
                        <label class="vs-input--label">Mensalidade GSM </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeGSM" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Mensalidade Satelite </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeSAT" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Mensalidade GSM + Satelite </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeSATeGSM" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Mensalidade SMS </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeSMS" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Franquia SMS </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.franquiaSMS" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Mensalidade Backup </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeBKP" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Mensalidade Celular </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeCelular" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Mensalidade Moto </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeMoto" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Adicional Mensalidade Chip Multioperadora </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeChipMultioperadora" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Adicional Mensalidade Identificador de Motorista </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeIdentificadorMotorista" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Adicional Mensalidade Atuador Entrada 1 a 3 </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeAtuadorSaida1a3" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Adicional Mensalidade Sensor RS232 </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeRS232" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Adicional Mensalidade Teclado </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeTeclado" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Adicional Mensalidade Telemetria </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeTelemetria" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Adicional Mensalidade Saida 1 a 3 </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeSensorEntradas1a3" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Verificação Visita</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.visitaVerificacao" :options="configurarNumerico2Digitos"></vue-autonumeric>

                        <label class="vs-input--label">Roteirização</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeRoteirizacao" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Controle de Pneus</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeControlePneus" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Controle de Frota</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeControleFrota" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Controle de Viagem</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeControleViagem" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Monitoramento da Central</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeMonitoramentoCentral" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Mensalidade Veículo Externo</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeVeiculoExterno" :options="configurarNumerico2Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Mensalidade Histórico Veículo</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.mensalidadeVeiculoInativo" :options="configurarNumerico2Digitos"></vue-autonumeric>                        
                        
                    </vs-col>
                </vs-row>
            </vx-card>
        </vs-col>
        <vs-col vs-justify="flex-start" vs-align="center" vs-w="3">  
            <vx-card class="tabelaComScroll" >
                <vs-row>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="12">           
                        <h5> Fidelização</h5>
                        <label class="vs-input--label">Fidelização GSM</label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.fidelizacaoGSM" :options="configurarNumerico0Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Fidelização SAT </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.fidelizacaoSAT" :options="configurarNumerico0Digitos"></vue-autonumeric>
                        <label class="vs-input--label">Fidelização GMS e SAT </label>
                        <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="tabelaPreco.fidelizacaoGSMeSAT" :options="configurarNumerico0Digitos"></vue-autonumeric>
                    </vs-col>
                </vs-row>   
            </vx-card>
        </vs-col>
        <vs-col vs-w="1" vs-justify="space-between">
            <vs-button class="w-full ml-2" size="small" @click="gravar" color="success" v-if="temPermissaoParaGravar">Gravar</vs-button>            
        </vs-col>
    </vs-row>
        
  </div>
</template>

<script>
import axios from "@/axios.js"
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';

export default {   
    components: {
        VueAutonumeric
    },     
    data() {
        return {            
            tabelaPreco: {                
                nome: null,
                instalacao: 165,
                desinstalacao: 100,
                bloqueio: 75,
                sensorRpm: 150,
                odometro: 150,
                sensorSimples: 100,
                sensorTanque: 350,
                botaoPanico: 45,
                visitaVerificacao: 50,
                mensalidadeGSM: 80,
                mensalidadeSAT: 125,
                mensalidadeSMS: 0,
                franquiaSMS: 0,
                mensalidadeBKP: 85,
                mensalidadeSATeGSM: 165,
                mensalidadeIdentificadorMotorista: 10,
                mensalidadeAtuadorSaida1a3: 5,
                mensalidadeRS232: 15,
                mensalidadeTeclado: 10,
                mensalidadeTelemetria: 65,
                mensalidadeSensorEntradas1a3: 5,
                mensalidadeMoto: 50,
                mensalidadeChipMultioperadora: 10,
                mensalidadeCelular: 5,
                instalacaoRastreadorBloqueio: 165,
                instalacaoRastreadorBloqueioPanico: 165,
                instalacaoIdentificadorMotorista: 215,
                instalacaoTelemetria: 110,
                instalacaoRS232: 100,
                instalacaoTravas: 850,
                instalacaoAtuadorSimples: 100,
                instalacaoSAT: 275,
                instalacaoGSMeSAT: 326,
                fidelizacaoGSM: 24,
                fidelizacaoSAT: 36,
                fidelizacaoGSMeSAT: 36,
                mensalidadeRoteirizacao: 2.5,
                mensalidadeControlePneus: 2.5,
                mensalidadeControleFrota: 2.5,
                mensalidadeControleViagem: 2.5,
                mensalidadeMonitoramentoCentral: 5,
                mensalidadeVeiculoExterno: 25,
                mensalidadeVeiculoInativo: 12.5
            },            
            configurarNumerico2Digitos: [{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }],
            configurarNumerico0Digitos: [{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0' }],
            exibirTabela: false,
            errorsValidacao: [],  
            publicPath: process.env.BASE_URL
        }
    },
    computed: {
        temPermissaoParaGravar() {
            let perfilLogado = this.$store.state.AppActiveUser.perfil;
            if (perfilLogado === "EMPRESA" || perfilLogado === 'ADMINISTRADOR') {
                return true;
            }

            return false;
        }
    },
    methods: {                       
        gravar() {     
            if (!this.formularioValido()) {
                return;
            }       
            
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja gravar a tabela de preços padrão ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.gravarRequest
            });
        },
        gravarRequest() {

            if (!this.formularioValido()) {
                return;
            }

            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');            
            if (this.tabelaPreco.hasOwnProperty("id")) {
                params.append('id', this.tabelaPreco.id);      
            }              
            params.append("instalacao", this.tabelaPreco.instalacao);
            params.append("desinstalacao", this.tabelaPreco.desinstalacao);
            params.append("bloqueio", this.tabelaPreco.bloqueio);
            params.append("sensorrpm", this.tabelaPreco.sensorRpm);
            params.append("odometro", this.tabelaPreco.odometro);
            params.append("sensorsimples", this.tabelaPreco.sensorSimples);
            params.append("sensortanque", this.tabelaPreco.sensorTanque);
            params.append("botaopanico", this.tabelaPreco.botaoPanico);
            params.append("verificaovisita", this.tabelaPreco.visitaVerificacao);
            params.append("mensalidadeGSM", this.tabelaPreco.mensalidadeGSM);
            params.append("mensalidadeSAT", this.tabelaPreco.mensalidadeSAT);
            params.append("mensalidadeSMS", this.tabelaPreco.mensalidadeSMS);
            params.append("franquiaSMS", this.tabelaPreco.franquiaSMS);
            params.append("mensalidadeBKP", this.tabelaPreco.mensalidadeBKP);
            params.append("mensalidadeSATeGSM", this.tabelaPreco.mensalidadeSATeGSM);
            params.append("mensalidadeCelular", this.tabelaPreco.mensalidadeCelular);
            params.append("mensalidadeIdentificadorMotorista", this.tabelaPreco.mensalidadeIdentificadorMotorista);
            params.append("mensalidadeAtuadorSaida1a3", this.tabelaPreco.mensalidadeAtuadorSaida1a3);
            params.append("mensalidadeRS232", this.tabelaPreco.mensalidadeRS232);
            params.append("mensalidadeTeclado", this.tabelaPreco.mensalidadeTeclado);
            params.append("mensalidadeTelemetria", this.tabelaPreco.mensalidadeTelemetria);
            params.append("mensalidadeSensorEntradas1a3", this.tabelaPreco.mensalidadeSensorEntradas1a3);
            params.append("instalacaoRastreadorBloqueio", this.tabelaPreco.instalacaoRastreadorBloqueio);
            params.append("instalacaoRastreadorBloqueioPanico", this.tabelaPreco.instalacaoRastreadorBloqueioPanico);
            params.append("instalacaoIdentificadorMotorista", this.tabelaPreco.instalacaoIdentificadorMotorista);
            params.append("instalacaoTelemetria", this.tabelaPreco.instalacaoTelemetria);
            params.append("instalacaoRS232", this.tabelaPreco.instalacaoRS232);
            params.append("instalacaoTravas", this.tabelaPreco.instalacaoTravas);
            params.append("instalacaoAtuadorSimples", this.tabelaPreco.instalacaoAtuadorSimples);
            params.append("instalacaoSAT", this.tabelaPreco.instalacaoSAT);
            params.append("instalacaoGSMeSAT", this.tabelaPreco.instalacaoGSMeSAT);
            params.append("mensalidadeMoto", this.tabelaPreco.mensalidadeMoto);
            params.append("mensalidadeChipMultioperadora", this.tabelaPreco.mensalidadeChipMultioperadora);
            params.append("fidelizacaoGSM", this.tabelaPreco.fidelizacaoGSM);
            params.append("fidelizacaoSAT", this.tabelaPreco.fidelizacaoSAT);
            params.append("fidelizacaoGSMeSAT", this.tabelaPreco.fidelizacaoGSMeSAT);

            params.append("mensalidadeRoteirizacao", this.tabelaPreco.mensalidadeRoteirizacao);
            params.append("mensalidadeControlePneus", this.tabelaPreco.mensalidadeControlePneus);
            params.append("mensalidadeControleFrota", this.tabelaPreco.mensalidadeControleFrota);
            params.append("mensalidadeControleViagem", this.tabelaPreco.mensalidadeControleViagem);
            params.append("mensalidadeMonitoramentoCentral", this.tabelaPreco.mensalidadeMonitoramentoCentral);
            params.append("mensalidadeVeiculoExterno", this.tabelaPreco.mensalidadeVeiculoExterno);
            params.append("mensalidadeVeiculoInativo", this.tabelaPreco.mensalidadeVeiculoInativo);                       

            axios.post("/GravarTabelaPrecoCliente", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    if (response.data.mensagem.indexOf('Erro') == -1 ) {
                        this.$vs.notify({
                                title: "Mensagem", text: response.data.mensagem, time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                        this.fetchTabela();
                    } else {
                        this.$vs.notify({
                            title: "Erro", text: response.data.mensagem, time: 10000,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                    }
                    
                                       
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);                 
            })  
        },        
        fetchTabela() {     
            this.exibirTabela = false;
            this.tabelaPreco = null;
            
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');            
            
            axios.post("/RecuperarTabelaPrecoCentral", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {                   
                    this.tabelaPreco = response.data;                    
                    this.exibirTabela = true;
                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },        
        valorValido(valor){
           if (!valor && valor !== 0) {
               return false;
           }
            
            return true
        },
        formularioValido() {
            this.$refs.errosComponent.iniciar();
            this.errorsValidacao = [];

            if (!this.valorValido(this.tabelaPreco.instalacao)) { this.errorsValidacao.push("Valor da instalação obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.desinstalacao)) { this.errorsValidacao.push("Valor da desinstalação obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.bloqueio)) { this.errorsValidacao.push("Valor da instalação do bloqueio obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.sensorRpm)) { this.errorsValidacao.push("Valor da instalação do sensor de rpm obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.odometro)) { this.errorsValidacao.push("Valor da instalação do sensor de odômetro obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.sensorSimples)) { this.errorsValidacao.push("Valor da instalação do sensor simples obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.sensorTanque)) { this.errorsValidacao.push("Valor da instalação do sensor de tanque obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.botaoPanico)) { this.errorsValidacao.push("Valor da instalação do botão de pânico obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.visitaVerificacao)) { this.errorsValidacao.push("Valor da visita de verificação obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeGSM)) { this.errorsValidacao.push("Valor da mensalidade GSM obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeSAT)) { this.errorsValidacao.push("Valor da mensalidade SAT obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeSMS)) { this.errorsValidacao.push("Valor da mensalidade de SMS obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.franquiaSMS)) { this.errorsValidacao.push("Valor da franquia de SMS obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeBKP)) { this.errorsValidacao.push("Valor da mensalidade de backup obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeSATeGSM)) { this.errorsValidacao.push("Valor da mensaldiade GSM e SAT obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeIdentificadorMotorista)) { this.errorsValidacao.push("Valor da mensalidade do identificador de motorista obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeAtuadorSaida1a3)) { this.errorsValidacao.push("Valor da mensalidade dos atuadores 1 a 3 obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeRS232)) { this.errorsValidacao.push("Valor da mensalidade RS232 obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeTeclado)) { this.errorsValidacao.push("Valor da mensalidade do teclado obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeTelemetria)) { this.errorsValidacao.push("Valor da mensalidade de telemetria obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeSensorEntradas1a3)) { this.errorsValidacao.push("Valor da sensor das entradas 1 a 3 obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeMoto)) { this.errorsValidacao.push("Valor da mensalidade de moto obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeChipMultioperadora)) { this.errorsValidacao.push("Valor da mensalidade de chip multioperadora obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeCelular)) { this.errorsValidacao.push("Valor mensalidade de celular obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.instalacaoRastreadorBloqueio)) { this.errorsValidacao.push("Valor da instalação rastreador com bloqueio obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.instalacaoRastreadorBloqueioPanico)) { this.errorsValidacao.push("Valor da instalação rastreador com bloqueio e pânico obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.instalacaoIdentificadorMotorista)) { this.errorsValidacao.push("Valor da instalação identificador motorista obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.instalacaoTelemetria)) { this.errorsValidacao.push("Valor da instalação Telemetria obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.instalacaoRS232)) { this.errorsValidacao.push("Valor da instalação RS2321 obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.instalacaoTravas)) { this.errorsValidacao.push("Valor da instalação tavas obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.instalacaoAtuadorSimples)) { this.errorsValidacao.push("Valor da instalação atuador simples obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.instalacaoSAT)) { this.errorsValidacao.push("Valor da instalação SAT obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.instalacaoGSMeSAT)) { this.errorsValidacao.push("Valor da instalação GSM e SAT  obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.fidelizacaoGSM)) { this.errorsValidacao.push("Fidelização GSM obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.fidelizacaoSAT)) { this.errorsValidacao.push("Fidelização SAT obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.fidelizacaoGSMeSAT)) { this.errorsValidacao.push("Fidelização para GSM e SAT obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeRoteirizacao)) { this.errorsValidacao.push("Valor mensalidade módulo de roteirização obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeControlePneus)) { this.errorsValidacao.push("Valor mensalidade módulo controle de pneus obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeControleFrota)) { this.errorsValidacao.push("Valor mensalidade módulo controle de frota obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeControleViagem)) { this.errorsValidacao.push("Valor mensalidade módulo de controle de viagens obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeMonitoramentoCentral)) { this.errorsValidacao.push("Valor mensalidade de monitoramento por central obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeVeiculoExterno)) { this.errorsValidacao.push("Valor mensalidade de veículo externo é obrigatório"); }
            if (!this.valorValido(this.tabelaPreco.mensalidadeVeiculoInativo)) { this.errorsValidacao.push("Valor mensalidade de histórico veículo  é obrigatório"); }
            
            
            
            if (!this.errorsValidacao.length) {
                return true;
            }   
        
            return false;
        }
    },
    mounted() {  
        this.fetchTabela();                     
    }
   
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 200px);
      position: relative;      
    } 
</style>