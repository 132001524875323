<template>
  <div>       
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="mr-4 p-0">
                 <label class="vs-input--label">Cliente:</label>
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="dadosAtivacao.clienteSelecionado"      
                    @input="fetchListaVeiculos"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
                
            </vx-card>
        </vs-col>
    </vs-row> 
    
    <div >
        <form-wizard class="p-0" ref="fwAtivacao" color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-warning), 1)" :title="null" :subtitle="null" stepSize="xs" backButtonText="<" nextButtonText=">" finishButtonText="Ativar" @on-complete="ativar" @on-error="exibirMenssageErro">
            <tab-content :title="getTituloTab1" icon="feather icon-truck" :before-change="validarPasso1">
                <!-- tab 1 content -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <vs-table :data="listaVeiculos" class="tabelaComScroll w-full mr-4" noDataText="..." maxItems="500" search hoverFlat stripe v-model="veiculoSelecionado" @selected="selecionarVeiculo">
                            <template slot="header">                                
                                <h6 class="ml-auto mt-2" v-if="listaVeiculos.length > 0 "> {{listaVeiculos.length}} veículos inativos</h6>                                   
                            </template>
                            <template slot="thead">
                                <vs-th class="p-0">Veículo</vs-th>                    
                                <vs-th class="p-0">Placa</vs-th>                    
                                <vs-th class="p-0">Último Rastreador</vs-th>                    
                                <vs-th class="p-0">Ativação</vs-th>            
                                <vs-th class="p-0">Desativação</vs-th>        
                            </template>
                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                                    <vs-td  class="p-0"><small>{{tr.marca}} {{getModelo(tr)}} </small> </vs-td>
                                    <vs-td  class="p-0"><small class="font-bold" style='font-family: Verdana, sans-serif;'> {{tr.placa}} </small></vs-td>
                                    <vs-td  class="p-0">
                                            <small class="font-bold">{{tr.codigoModulo}}</small>  <small>{{tr.marcaModulo}}</small>
                                    </vs-td>                                    
                                    <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.dataAtivacao}}</small></vs-td>                                                           
                                    <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.dataDesativacao}}</small></vs-td>                                                           
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>                
            </tab-content>
            <tab-content title="2. Dados Básicos do Veículo" icon="feather icon-layers" :before-change="validarPasso2" >
                <div class="vx-row" v-if="dadosAtivacao.veiculo">                        
                    <div class="vx-col w-1/3 ">
                        <vx-card v-if="dadosAtivacao.veiculo">                            
                            <label class="vs-input--label" >Tipo Veiculo</label> 
                            <v-select                       
                                label="label" 
                                :reduce="tipoVeiculo => tipoVeiculo.data" 
                                :options="listaTiposVeiculos" 
                                v-model="dadosAtivacao.veiculo.tipoVeiculo"     
                                style="height: 25px"
                                class="mb-4">
                                <template slot="option" slot-scope="option">
                                    <img :src="`${publicPath}${option.fullImage}`">
                                </template>
                                <template #selected-option="{ fullImage }">
                                    <img :src="`${publicPath}${fullImage}`" style="max-height:25px">
                                </template>
                            </v-select> 

                            <label class="vs-input--label" >Marca</label> 
                            <v-select                       
                                label="marca"        
                                :reduce="m => m.marca"                
                                :options="listaMarcaVeiculos" 
                                v-model="dadosAtivacao.veiculo.marca"                      
                                class="w-full">
                            </v-select> 
                             
                            <label class="vs-input--label" >Modelo</label> 
                            <v-select                       
                                label="modelo"        
                                :reduce="m => m.modelo"                
                                :options="listaModeloVeiculos" 
                                v-model="dadosAtivacao.veiculo.modelo"                      
                                class="w-full">
                            </v-select> 
                            <div v-if="dadosAtivacao.veiculo.modelo === 'Outro'">
                                <label class="vs-input--label">Outro Modelo</label>
                                <vs-input class="w-full" v-model="dadosAtivacao.veiculo.modeloOutro" icon="label"/>
                            </div> 
                        </vx-card>
                    </div>
                    <div class="vx-col w-1/3">
                        <vx-card v-if="dadosAtivacao.veiculo">
                            <div class="vx-row">
                                <div class="vx-col w-1/2">    
                                    <label class="vs-input--label" >Placa</label> 
                                    <vs-input class="w-full" v-model="dadosAtivacao.veiculo.placa" v-mask="['AAA-#X##', 'AAA-#X##']" icon="calendar_view_day" :disabled="dadosAtivacao.veiculo.idVeiculo" />
                                </div>
                                <div class="vx-col w-1/2">    
                                    <label class="vs-input--label" >Label</label> 
                                    <vs-input class="w-full" v-model="dadosAtivacao.veiculo.labelVeiculo" />
                                </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col w-full">    
                                    <label class="vs-input--label" >Cor</label> 
                                    <vs-input class="w-full" v-model="dadosAtivacao.veiculo.corVeiculo" icon="color_lens" />
                                </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col w-1/2">    
                                    <label class="vs-input--label" >Ano Fabricação</label> 
                                    <vs-input class="w-full" v-model="dadosAtivacao.veiculo.anoFabricacao" v-mask="['####']" />
                                </div>
                                <div class="vx-col w-1/2">    
                                    <label class="vs-input--label" >Ano Modelo</label> 
                                    <vs-input class="w-full" v-model="dadosAtivacao.veiculo.anoModelo"  v-mask="['####']" />
                                </div>
                            </div>
                            <vx-input-group>
                                <label class="vs-input--label" >Condutor</label> 
                                <vs-input class="w-full" v-model="dadosAtivacao.veiculo.condutor" icon-pack="feather"  v-if="!idNovoMotorista" icon="icon-user" v-on:keyup="idNovoMotorista = null"/>

                                 <v-select   
                                    v-if="idNovoMotorista"
                                    label="nome"
                                    :reduce="m => m.id"
                                    :options="listaMotoristas"                   
                                    v-model="idNovoMotorista" 
                                    @input="selecionaMotorista"                                        
                                    class="v-select w-full">
                                    <template #option="{  nome, cpf, codigoChaveiro  }">
                                        <small><strong> {{nome}} </strong>  
                                        <em> - {{cpf}}</em> <em v-if="codigoChaveiro"> - {{codigoChaveiro}} </em></small>
                                    </template>
                                    <template #selected-option="{ nome  }">                          
                                        <small style="font-size:0.70vw">{{nome}} </small>
                                    </template>
                                    <template #no-options="">
                                        Nenhum motorista cadastrado.
                                    </template>
                                </v-select> 

                                <template slot="append">
                                    <div class="append-text btn-addon mt-5">
                                        <vs-button color="dark"  icon-pack="feather" icon="icon-user" @click="novoMotorista"></vs-button>                                                      
                                    </div>
                                </template>
                            </vx-input-group>
                            
                        </vx-card>
                    </div>
                    <div class="vx-col w-1/3">
                        <vx-card>
                            <div class="vx-row">
                                <div class="vx-col w-1/2">    
                                    <label class="vs-input--label">KM/l</label>
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" v-model="dadosAtivacao.veiculo.autonomia" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>
                                </div>
                                <div class="vx-col w-1/2">    
                                    <label class="vs-input--label">L/h</label>
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" v-model="dadosAtivacao.veiculo.autonomiaPorHora" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>
                                </div>
                            </div>                            
                        </vx-card>
                    </div>
                </div>
            </tab-content>                 
                  
        </form-wizard>
    </div>
        
  </div>
</template>

<script>
import axios from "@/axios.js"
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { CONSTANTE_MARCAS_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_VEICULO } from '@/constantesImagens.js'
import { CONSTANTE_TIPO_CONTRATO_VEICULO } from '@/constantesComboBox.js'

export default {   
    components: {
        FormWizard,
        TabContent,
        VueAutonumeric
    }, 
    created() {
        this.CONSTANTE_MARCAS_MODULO = CONSTANTE_MARCAS_MODULO;
        this.listaTiposVeiculos = CONSTANTE_TIPO_VEICULO;  
        this.CONSTANTE_TIPO_CONTRATO_VEICULO = CONSTANTE_TIPO_CONTRATO_VEICULO;
    },
    computed: {
        getTituloTab1() {
            if (this.veiculoSelecionado && this.veiculoSelecionado.idVeiculo) {
                return "1. Veículo selecionado: " + this.veiculoSelecionado.placa;
            } else if (this.veiculoSelecionado && !this.veiculoSelecionado.idVeiculo) {
                return "1. Novo Veículo";
            } else {
                return "1.Selecione o veículo.";
            }
        },
        isDadosContratoReaonly() {
            if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||               
                 (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
                 (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR')  ||
                 (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')  ) {
                   return false;
            }

            return true;
        }   
    },
    data() {
        return {                      
            dadosAtivacao: {
                modulo : { marca: null, serial: null },
                moduloDisponivel: false,                
                veiculo: { tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, labelVeiculo: null, corVeiculo: null, anoFabricacao: null, anoModelo: null, 
                    autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, possuiBotaoPanico: false,
                    possuiBloqueio: false, centralMonitoramento: false, procedimentoPanico: null, 
                    odometroAtual: 0,
                    nome1ContatoPanico: null, telefone1ContatoPanico: null,
                    nome2ContatoPanico: null, telefone2ContatoPanico: null,
                    nome3ContatoPanico: null, telefone3ContatoPanico: null,
                    emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null, celularlAvisoExcessoVelocidade: null, celularlAvisoSaidaCerca: null  },
                clienteSelecionado: null,                
                dadosAdicionaisClienteSelecionado: null,
            },
            listaClientes: [],
            listaVeiculos: [],              
            listaMarcaVeiculos: [], 
            listaModeloVeiculos: [],             
            veiculoSelecionado: null,                    
            idNovoMotorista: null,        
            listaMotoristas: [],          
            publicPath: process.env.BASE_URL
        }
    },
    methods: {
        novoMotorista(){
            if (this.idNovoMotorista) {
                this.idNovoMotorista = null;
                this.dadosAtivacao.veiculo.condutor = null;
            } else {
                if (!this.idNovoMotorista && this.listaMotoristas && this.listaMotoristas.length > 0) {
                    this.idNovoMotorista = this.listaMotoristas[0].id;            
                    this.dadosAtivacao.veiculo.condutor = this.listaMotoristas[0].nome;
                }
            }
        },
        selecionaMotorista(idMotoristaSelecionado) {
            
            if (!idMotoristaSelecionado) {
                this.dadosAtivacao.veiculo.condutor = null;
            } else {
                this.dadosAtivacao.veiculo.condutor = null;
                if (this.listaMotoristas && this.listaMotoristas.length > 0) {
                    for(var i = 0; i < this.listaMotoristas.length; i++) {
                        if (this.listaMotoristas[i].id === idMotoristaSelecionado) {
                            this.dadosAtivacao.veiculo.condutor = this.listaMotoristas[i].nome;
                            break;
                        }
                    }
                } 
            }
        },
        getModelo(tr) {
            if (tr.modelo === 'Outro') {
                return tr.modeloOutro;
            } else {
                return tr.modelo;
            }
        },
        novoVeiculo() {            
            if (this.dadosAtivacao.dadosAdicionaisClienteSelecionado.id == 38) {
                this.veiculoSelecionado = { tipoVeiculo: "PORTATIL", marca: 'Agrale', modelo:'10.160', modeloOutro: null, placa: null, condutor: null, labelVeiculo: null, corVeiculo: 'BRANCA', anoFabricacao: null, anoModelo: null,
                        autonomia: 10, autonomiaPorHora: 2, mensalidade: null, tipoContratoVeiculo: 'EQUIPAMENTO_CLIENTE', prazoContratoVeiculoEmMeses: 12, possuiBotaoPanico: false,
                        possuiBloqueio: false, centralMonitoramento: false, procedimentoPanico: null, 
                        odometroAtual: 1,
                        nome1ContatoPanico: null, telefone1ContatoPanico: null,
                        nome2ContatoPanico: null, telefone2ContatoPanico: null,
                        nome3ContatoPanico: null, telefone3ContatoPanico: null,
                        emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null, celularlAvisoExcessoVelocidade: null, celularlAvisoSaidaCerca: null,
                };
                this.dadosAtivacao.modulo.marca= 'Mobile';
            } else {
                this.veiculoSelecionado = { tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, labelVeiculo: null, corVeiculo: null, anoFabricacao: null, anoModelo: null,
                        autonomia: 10, autonomiaPorHora: 2, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, possuiBotaoPanico: false,
                        possuiBloqueio: false, centralMonitoramento: false, procedimentoPanico: null, 
                        odometroAtual: 0,
                        nome1ContatoPanico: null, telefone1ContatoPanico: null,
                        nome2ContatoPanico: null, telefone2ContatoPanico: null,
                        nome3ContatoPanico: null, telefone3ContatoPanico: null,
                        emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null, celularlAvisoExcessoVelocidade: null, celularlAvisoSaidaCerca: null };
            }

            this.veiculoSelecionado.emailAvisoExcessoVelocidade = this.dadosAtivacao.dadosAdicionaisClienteSelecionado.email;
            this.veiculoSelecionado.celularlAvisoExcessoVelocidade = this.dadosAtivacao.dadosAdicionaisClienteSelecionado.telefone;

            this.veiculoSelecionado.nome1ContatoPanico = this.dadosAtivacao.dadosAdicionaisClienteSelecionado.nome;
            this.veiculoSelecionado.telefone1ContatoPanico = this.dadosAtivacao.dadosAdicionaisClienteSelecionado.telefone;
            this.veiculoSelecionado.senha1ContatoPanico = this.dadosAtivacao.dadosAdicionaisClienteSelecionado.senhaBloqueio;
            
            if (this.dadosAtivacao.dadosAdicionaisClienteSelecionado.email2) {
                this.veiculoSelecionado.emailAvisoSaidaCerca = this.dadosAtivacao.dadosAdicionaisClienteSelecionado.email2;
            }
            if (this.dadosAtivacao.dadosAdicionaisClienteSelecionado.telefone2) {
                this.veiculoSelecionado.celularlAvisoSaidaCerca = this.dadosAtivacao.dadosAdicionaisClienteSelecionado.telefone2;
            }
            this.dadosAtivacao.veiculo = this.veiculoSelecionado;        
            if (this.listaMotoristas && this.listaMotoristas.length > 0) {
                this.idNovoMotorista = this.listaMotoristas[0].id;            
                this.dadosAtivacao.veiculo.condutor = this.listaMotoristas[0].nome;
            } else {
                this.idNovoMotorista = null;
                this.dadosAtivacao.veiculo.condutor = null;
            }

            this.$refs.fwAtivacao.nextTab();
        },        
        selecionarVeiculo(tr) {
            this.veiculoSelecionado = tr;
            this.dadosAtivacao.veiculo = tr;

            if (!this.dadosAtivacao.veiculo.monitoravelPorCentral) {
                this.dadosAtivacao.veiculo.monitoravelPorCentral = this.dadosAtivacao.dadosAdicionaisClienteSelecionado.monitoravelPorCentral;
            }

            if (!this.dadosAtivacao.veiculo.mensalidade) {
                this.dadosAtivacao.veiculo.mensalidade = this.dadosAtivacao.dadosAdicionaisClienteSelecionado.mensalidadeGSM;
            } 

            this.dadosAtivacao.veiculo.odometroAtual = 0;

            if (this.listaMotoristas && this.listaMotoristas.length > 0) {
                this.idNovoMotorista = this.listaMotoristas[0].id;            
                this.dadosAtivacao.veiculo.condutor = this.listaMotoristas[0].nome;
            } else {
                this.idNovoMotorista = null;
                this.dadosAtivacao.veiculo.condutor = null;
            }

            this.$refs.fwAtivacao.nextTab();
        },
        exibirMenssageErro: function(errorMsg){
            if (errorMsg) {
                this.$vs.notify({
                    title: "Erro", text: errorMsg, time: 10000,
                    iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
            }
        },
        validarPasso1() {
            let self_ = this;

            return new Promise((resolve, reject) => {
                if (self_.dadosAtivacao.veiculo) {
                    self_.dadosAtivacao.moduloNovoDisponivel = false;                                                            
                    return resolve(true);
                }
                return reject("Selecione um veículo");
            });
        },
        validarPasso2() {
            let self_ = this;

            return new Promise((resolve, reject) => {
                
                if (!self_.dadosAtivacao.veiculo.tipoVeiculo) { return reject("Informe o tipo do veículo"); }
                if (!self_.dadosAtivacao.veiculo.marca) { return reject("Informe a marca do veículo"); }
                if (!self_.dadosAtivacao.veiculo.modelo) { return reject("Informe o modelo do veículo"); }
                if (self_.dadosAtivacao.veiculo.modelo === 'Outro' && !self_.dadosAtivacao.veiculo.modeloOutro) {  return reject("Informe o outro modelo do veículo"); }
                if (!self_.dadosAtivacao.veiculo.placa) { return reject("Informe a placa do veículo"); }
                if (!self_.dadosAtivacao.veiculo.corVeiculo) { return reject("Informe a cor do veículo"); }
                if (!self_.dadosAtivacao.veiculo.autonomia) { return reject("Informe a autonomia do veículo"); }
                if (!self_.dadosAtivacao.veiculo.autonomiaPorHora) { return reject("Informe a autonomia por hora do veículo"); }
                if (!self_.dadosAtivacao.veiculo.condutor) { return reject("Informe o condutor do veículo"); }                
                
                return resolve(true);
                
            });
        },
          
        ativar() {
            let self_ = this;
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja ativar o histórico do veículo ' 
                +  self_.dadosAtivacao.veiculo.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.ativarRequest
            });
        },
        ativarRequest() {
            this.$vs.loading();  
            const params = new URLSearchParams();              
            params.append('outputFormat', 'JSON');
            
            params.append('idCliente', this.dadosAtivacao.clienteSelecionado.id);               
            params.append('idVeiculo', this.dadosAtivacao.veiculo.idVeiculo);   
            params.append('cnpjCPF', this.dadosAtivacao.clienteSelecionado.CNPJCPF);
            params.append('tipoVeiculo', this.dadosAtivacao.veiculo.tipoVeiculo);   
            params.append('marcaVeiculo', this.dadosAtivacao.veiculo.marca);   
            params.append('modeloVeiculo', this.dadosAtivacao.veiculo.modelo);   
            if (this.dadosAtivacao.veiculo.modeloOutro) {
                params.append('modeloVeiculoOutro', this.dadosAtivacao.veiculo.modeloOutro);   
            }
            params.append('placaVeiculo', this.dadosAtivacao.veiculo.placa);   
            params.append('autonomia', this.dadosAtivacao.veiculo.autonomia);   
            params.append('autonomiaPorHora', this.dadosAtivacao.veiculo.autonomiaPorHora);   
            params.append('corVeiculo', this.dadosAtivacao.veiculo.corVeiculo);               
            params.append('senhaBloqueio', this.dadosAtivacao.clienteSelecionado.senhaBloqueio);   

            if (this.dadosAtivacao.veiculo.anoFabricacao) {
                params.append('anoFabricacao', this.dadosAtivacao.veiculo.anoFabricacao);   
            }
            if (this.dadosAtivacao.veiculo.anoModelo) {
                params.append('anoModelo', this.dadosAtivacao.veiculo.anoModelo);   
            }
                        
            params.append('condutorNome', this.dadosAtivacao.veiculo.condutor);  
            if (this.idNovoMotorista) {
                params.append('idMotorista', this.idNovoMotorista);
            } 
            
            params.append('senhaBloqueio', this.dadosAtivacao.dadosAdicionaisClienteSelecionado.senhaBloqueio);
            params.append('odometroAtual', 0);

            if (this.dadosAtivacao.veiculo.labelVeiculo) {                
                params.append('labelVeiculo', this.dadosAtivacao.veiculo.labelVeiculo);
            }

            axios.post("/AtivarHistoricoVeiculo", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    if (response.data.statusMensagem === 0) {                        
                        this.dadosAtivacao.modulo = { marca: null, serial: null };
                        this.dadosAtivacao.moduloDisponivel = false;
                        this.dadosAtivacao.veiculo = { tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, labelVeiculo: null, corVeiculo: null, anoFabricacao: null, anoModelo: null,
                                        autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, possuiBotaoPanico: false,
                                        possuiBloqueio: false, centralMonitoramento: false, procedimentoPanico: null, odometroAtual: null, nome1ContatoPanico: null, telefone1ContatoPanico: null,
                                        nome2ContatoPanico: null, telefone2ContatoPanico: null, nome3ContatoPanico: null, telefone3ContatoPanico: null, emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null };
                        this.veiculoSelecionado = null;

                        this.$vs.notify({
                            title: "Mensagem", text: response.data.mensagem, time: 10000,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                    } else {
                        this.$vs.notify({
                            title: "Erro", text: response.data.mensagem, time: 10000,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                    }
                                       
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);                 
            })  
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },        
        fetchListaVeiculos() {     
            if (!this.dadosAtivacao.clienteSelecionado) {
                return;
            }
            
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');            
            params.append('cpfcnpj', this.dadosAtivacao.clienteSelecionado.CNPJCPF);
            
            axios.post("/RecuperarClientePorCNPJouCPFComVeiculosInativos", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaVeiculos = response.data.listaVeiculos;  
                    this.dadosAtivacao.dadosAdicionaisClienteSelecionado = response.data.dadosCliente;
                    this.dadosAtivacao.veiculo = null;

                    if (this.listaVeiculos.length > 0 && this.dadosAtivacao.veiculo && this.dadosAtivacao.veiculo.placa) {
                        this.veiculoSelecionado = this.dadosAtivacao.veiculo;
                        this.dadosAtivacao.moduloDisponivel = false;
                    } else {
                        this.veiculoSelecionado = null;
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })   
            
            this.fetchListaMotoristas();
                                
        },
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;         
                    if (this.$store.getters.dadosBackendAtivacao) {
                       this.dadosAtivacao = JSON.parse(JSON.stringify(this.$store.getters.dadosBackendAtivacao));
                       this.fetchListaVeiculos();
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        },         
        fetchListaMarcaVeiculos() {
            axios.get("/rest/MarcaVeiculo/Listar", null, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {                    
                this.listaMarcaVeiculos = response.data.Resultado.lista.item;
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("Error Fetch Lista Marcas Veiculos " + error);        
            })      
        },  
        fetchListaModeloVeiculos() {
            const params = new URLSearchParams();
            params.append('pagina', -1);
            axios.post("/rest/ModeloVeiculo/Listar", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {                    
                this.listaModeloVeiculos = response.data.Resultado.lista.item;
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("Error Fech Lista Modelo Veiculos " + error);        
            })      
        },         
        irTeste(){
            this.$router.push({
                name: 'backend-resultado-ativacao',
                params: { resultadoAtivacaoVeiculo: this.dadosAtivacao }
            })
        },        
        fetchListaMotoristas() {      
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('idCliente', this.dadosAtivacao.clienteSelecionado.id);
            params.append('motoristaAtivo', true); 
            
            axios.post("/MotoristasRest", params, 
                    { credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) { // Efetuar logoff                    
                    this.$router.push({ path: '/' })
                } else {
                    this.listaMotoristas = response.data;         
                    if (!this.idNovoMotorista && this.listaMotoristas && this.listaMotoristas.length > 0) {
                        this.idNovoMotorista = this.listaMotoristas[0].id;            
                        this.dadosAtivacao.veiculo.condutor = this.listaMotoristas[0].nome;
                    }    
                }
            })
            .catch((error) => { 
                /* eslint-disable no-console */   console.log("EFETUAR LOGOFF! - " + error);
            })                  
        },       
    },
    mounted() {          
        this.fetchListaClientes();
        this.fetchListaMarcaVeiculos();
        this.fetchListaModeloVeiculos();        
    }
    
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 370px);
      position: relative;      
    } 
</style>