<template>
  <div>
    <vs-row class="p-0 mb-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="mr-4 p-0">
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    placeholder="Selecione o cliente"
                    :filter="pesquisarCliente"
                    v-model="dadosAtivacao.clienteSelecionado"      
                    @input="selecionarCliente"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
                
            </vx-card>
        </vs-col>
    </vs-row> 
    <vs-row v-if="exibirMensagemNenhumDadoEncontrado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">           
            <vs-alert class="mt-4 mr-4" color="warning" title="Atenção">
                Não existem veículos ativos.
            </vs-alert>                          
        </vs-col>
    </vs-row>   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <div v-if="dadosAtivacao.clienteSelecionado">
        <form-wizard class="p-0 mb-0" ref="fwAtivacao" color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-warning), 1)" :title="null" :subtitle="null" stepSize="xs" backButtonText="<" nextButtonText=">" finishButtonText="Gravar" @on-complete="ativar" @on-error="exibirMenssageErro">
            <tab-content :title="getTituloTab1" icon="feather icon-truck" :before-change="validarPasso1">
                <!-- tab 1 content -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="flex mb-2 items-center" v-if="dadosAtivacao.clienteSelecionado">
                            <div class="w-full"> 
                                <label class="vs-input--label">Divisão:</label> 
                                <v-select                                                           
                                    label="nome"                                
                                    :options="listaDivisoesVeiculo" 
                                    v-model="divisaoSelecionada"                                        
                                    class="vs-input--label w-full" >
                                </v-select> 
                            </div>
                            <div class="w-full ml-2"> 
                                <label class="vs-input--label" >Termo:</label> 
                                <vx-input-group>     
                                    <input class="vs-input--input w-full" type="text"  style="min-width: 172px; line-height: 24px;" name="txtTermoPesquisa" id="txtTermoPesquisa" ref="txtTermoPesquisa"  v-on:keyup.13="pesquisarPorTermo($refs.txtTermoPesquisa.value)"/> 
                                    <template slot="append">
                                        <div class="append-text btn-addon">
                                            <vs-button color="dark" size="small" icon-pack="feather" icon="icon-search" @click="pesquisarPorTermo($refs.txtTermoPesquisa.value)"></vs-button>                                                
                                        </div>
                                    </template>
                                </vx-input-group>
                            </div>                    
                            <div class="flex mt-6 ml-2">                                
                                <h6 class="ml-2 mt-2 mr-2" v-if="listaVeiculos.length > 0 " style="white-space: nowrap;"> {{qtdRegistros}} / {{listaVeiculos.length}}</h6>
                            </div>
                        </div>
                        <vs-table ref="table" :data="filteredVeiculos" class="tabelaComScroll w-full mr-4" noDataText="..." maxItems="500" hoverFlat stripe multiple v-model="listaVeiculoSelecionados">                          
                            <template slot="thead">
                                <vs-th class="p-0">Veículo</vs-th>                    
                                <vs-th class="p-0">Placa</vs-th>                    
                                <vs-th class="p-0">Último Rastreador</vs-th>                    
                                <vs-th class="p-0">Ativação</vs-th>            
                            </template>
                            <template slot-scope="{data}">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                                    <vs-td  class="p-0"><small>{{tr.marca}} {{getModelo(tr)}} </small> </vs-td>
                                    <vs-td  class="p-0"><small class="font-bold" style='font-family: Verdana, sans-serif;'> {{tr.placa}} </small></vs-td>
                                    <vs-td  class="p-0">
                                            <small class="font-bold">{{tr.codigoModulo}}</small>  <small>{{tr.marcaModulo}}</small>
                                    </vs-td>                                    
                                    <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.dataAtivacao}}</small></vs-td>                                                           
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>                
            </tab-content>
            <tab-content title="2. Dados do Veículo" icon="feather icon-tablet">
                <vs-tabs alignment="fixed" v-model="tabSelecionado" v-if="listaVeiculoSelecionados && listaVeiculoSelecionados.length > 0 " >
                    <vs-tab label="Dados Básicos" style="min-height: 250px">
                        <div class="vx-row">                        
                            <div class="vx-col w-1/2">
                                <vx-card>        
                                    <div class="vx-row">                        
                                        <div class="vx-col w-1/2">                    
                                            <label class="vs-input--label" >Tipo Veiculo</label> 
                                            <v-select                       
                                                label="label" 
                                                :reduce="tipoVeiculo => tipoVeiculo.data" 
                                                :options="listaTiposVeiculos" 
                                                v-model="dadosAtivacao.veiculo.tipoVeiculo"     
                                                style="height: 25px"
                                                class="style-chooser mb-4">
                                                <template slot="option" slot-scope="option">
                                                    <img :src="`${publicPath}${option.fullImage}`">
                                                </template>
                                                <template #selected-option="{ fullImage }">
                                                    <img :src="`${publicPath}${fullImage}`" style="max-height:25px">
                                                </template>
                                            </v-select> 
                                        </div>
                                        <div class="vx-col w-1/2">                    
                                            <label class="vs-input--label" >Marca</label> 
                                            <v-select                       
                                                label="marca"        
                                                :reduce="m => m.marca"                
                                                :options="listaMarcaVeiculos" 
                                                v-model="dadosAtivacao.veiculo.marca"                      
                                                class="style-chooser w-full">
                                            </v-select> 
                                        </div>
                                    </div>
                                    
                                    <label class="vs-input--label" >Modelo</label> 
                                    <v-select                       
                                        label="modelo"        
                                        :reduce="m => m.modelo"                
                                        :options="listaModeloVeiculos" 
                                        v-model="dadosAtivacao.veiculo.modelo"                      
                                        class="style-chooser w-full">
                                    </v-select> 
                                    <div v-if="dadosAtivacao.veiculo.modelo === 'Outro'">
                                        <label class="vs-input--label">Outro Modelo</label>
                                        <vs-input class="w-full" v-model="dadosAtivacao.veiculo.modeloOutro" icon="label"/>
                                    </div> 
                                </vx-card>
                            </div>                               
                            <div class="vx-col w-1/2">
                                <vx-card>                            
                                    <div class="vx-row">                                        
                                        <div class="vx-col w-1/3">    
                                            <label class="vs-input--label" >Cor</label> 
                                            <vs-input class="w-full" v-model="dadosAtivacao.veiculo.corVeiculo" icon="color_lens" />
                                        </div>                                                                          
                                        <div class="vx-col w-1/3">    
                                            <label class="vs-input--label">KM/l</label>
                                            <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" v-model="dadosAtivacao.veiculo.autonomia" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2', emptyInputBehavior: 'null' }]"></vue-autonumeric>
                                        </div>
                                        <div class="vx-col w-1/3">    
                                            <label class="vs-input--label">L/h</label>
                                            <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full" v-model="dadosAtivacao.veiculo.autonomiaPorHora" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2', emptyInputBehavior: 'null' }]"></vue-autonumeric>
                                        </div>
                                       
                                    </div>                                   
                                </vx-card>
                            </div>                               
                        </div>
                    </vs-tab>
                    <vs-tab  label="Configuração">
                        <vx-card>
                            <div class="vx-row">
                                <div class="vx-col w-1/4">    
                                    <label class="vs-input--label" >Manter motorista quando desligar ?</label>
                                    <v-select                             
                                        :options="listaOpcoesSIM_NAO"                   
                                        :reduce="tipo => tipo.data" 
                                        v-model="dadosAtivacao.veiculo.manterMotoristaVeiculoDesligado"       
                                        class="v-select">
                                    </v-select>                                          
                                </div>                                
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label" >Atualização / por minuto </label> 
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.taxaAtualizacao" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2', emptyInputBehavior: 'null' }]"></vue-autonumeric>
                                </div>
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label" >Atualização RPM/CAN / minuto</label> 
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.taxaAtualizacaoRPM" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2', emptyInputBehavior: 'null' }]"></vue-autonumeric>
                                </div>
                                 <div class="vx-col w-1/4">    
                                      <label class="vs-input--label" >Possui Monitriip</label>
                                      <v-select                             
                                        :options="listaOpcoesSIM_NAO"                   
                                        :reduce="tipo => tipo.data" 
                                        v-model="dadosAtivacao.veiculo.possuiMonitriip"       
                                        class="v-select">                                        
                                      </v-select>                                             
                                    </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label">Origem Dados Odômetro</label> 
                                    <v-select                                                       
                                        :reduce="m => m.data"                
                                        :options="CONSTANTE_ORIGEM_DADOS" 
                                        v-model="dadosAtivacao.veiculo.origemDadosOdometro"                      
                                        class="w-full">
                                    </v-select> 
                                </div>
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label">Origem Dados Horímetro</label> 
                                    <v-select                                                       
                                        :reduce="m => m.data"                
                                        :options="CONSTANTE_ORIGEM_DADOS" 
                                        v-model="dadosAtivacao.veiculo.origemDadosHorimetro"                      
                                        class="w-full">
                                    </v-select> 
                                </div>
                                <div class="vx-col w-1/4">    
                                        <label class="vs-input--label" >Ativar Espelhamento ?</label>
                                        <v-select                             
                                            :options="listaOpcoesSIM_NAO"                   
                                            :reduce="tipo => tipo.data" 
                                            v-model="dadosAtivacao.veiculo.ativarEspelhamento"       
                                            class="v-select">
                                        </v-select>                                               
                                    </div>
                                    <div class="vx-col w-1/4">   
                                        <label class="vs-input--label">Em manutenção ?</label>
                                        <v-select                             
                                            :options="listaOpcoesSIM_NAO"                   
                                            :reduce="tipo => tipo.data" 
                                            v-model="dadosAtivacao.veiculo.emManutencao"       
                                            class="v-select">
                                        </v-select>                                           
                                    </div>
                            </div>
                            <div class="vx-row mt-2">                                   
                                <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label" >Alerta Sem Conexão (minutos) </label> 
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.tempoSemConexaoParaAlerta" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0', emptyInputBehavior: 'null' }]"></vue-autonumeric>
                                </div>
                                 <div class="vx-col w-1/4"> 
                                    <label class="vs-input--label" >Alerta Sem Conexão Ligado (minutos) </label> 
                                    <vue-autonumeric class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0', emptyInputBehavior: 'null' }]"></vue-autonumeric>
                                </div>
                                   
                            </div>
                        </vx-card>
                    </vs-tab>
                    <vs-tab  label="Contrato e Mensalidade" style="min-height: 250px">
                        <div class="vx-row">                        
                            <div class="vx-col w-1/2">
                                <vx-card>
                                    <h5> Contrato</h5>     
                                    <div class="vx-row">
                                        <div class="vx-col w-2/3"> 
                                            <label class="vs-input--label" >Contrato</label> 
                                            <v-select                                                       
                                                :reduce="m => m.data"    
                                                :disabled="!habilitarEdicaoMensalidades"            
                                                :options="CONSTANTE_TIPO_CONTRATO_VEICULO" 
                                                v-model="dadosAtivacao.veiculo.tipoContratoVeiculo"                      
                                                class="w-full">
                                            </v-select> 
                                        </div>
                                        <div class="vx-col w-1/3"> 
                                            <label class="vs-input--label" >Prazo (meses)</label> 
                                            <vue-autonumeric :disabled="!habilitarEdicaoMensalidades" class="vs-inputx vs-input--input normal hasValue w-full"  v-model="dadosAtivacao.veiculo.prazoContratoVeiculoEmMeses" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'0' , emptyInputBehavior: 'null'}]"></vue-autonumeric>
                                        </div>
                                    </div>                                    
                                </vx-card>
                            </div>
                            <div class="vx-col w-1/2">
                                <vx-card>
                                    <h5> Mensalidade </h5>                                        
                                    <label class="vs-input--label">Valor da Mensalidade R$ </label>
                                    <vue-autonumeric  :disabled="!habilitarEdicaoMensalidades" class="vs-inputx vs-input--input normal hasValue w-full" v-model="dadosAtivacao.veiculo.mensalidade" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2', emptyInputBehavior: 'null' }]"></vue-autonumeric>                                                           

                                    <label class="vs-input--label" v-if="dadosAtivacao.veiculo.marcaModuloBkp">Valor da Mensalidade Backup R$ </label>
                                    <vue-autonumeric  :disabled="!habilitarEdicaoMensalidades" v-if="dadosAtivacao.veiculo.marcaModuloBkp" class="vs-inputx vs-input--input normal hasValue w-full" v-model="dadosAtivacao.veiculo.mensalidadeBkp" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2', emptyInputBehavior: 'null' }]"></vue-autonumeric>                                                           
                                </vx-card>
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab  label="Emails e Contatos" style="min-height: 250px">
                        <vx-card>
                             <div class="vx-row">
                                    <div class="vx-col w-1/3">    
                                        <label class="vs-input--label" >Possui Botão de Pânico</label>
                                        <v-select                             
                                            :options="listaOpcoesSIM_NAO"                   
                                            :reduce="tipo => tipo.data" 
                                            v-model="dadosAtivacao.veiculo.possuiBotaoPanico"       
                                            class="v-select">                                            
                                        </v-select>                                          
                                    </div>
                                    <div class="vx-col w-1/3">    
                                        <label class="vs-input--label" >Possui Bloqueio</label>
                                        <v-select                             
                                            :options="listaOpcoesSIM_NAO"                   
                                            :reduce="tipo => tipo.data" 
                                            v-model="dadosAtivacao.veiculo.possuiBloqueio"       
                                            class="v-select">
                                        </v-select>  
                                        
                                    </div>
                                    <div class="vx-col w-1/3">   
                                        <label class="vs-input--label" >Monitorável pela Central de Monitoramento</label>
                                        <vx-tooltip :text="getTextoMonitoravelCentral">   
                                            <v-select                             
                                                :options="listaOpcoesSIM_NAO"                   
                                                :reduce="tipo => tipo.data" 
                                                v-model="dadosAtivacao.veiculo.possuiCentralMonitoramento"  
                                                :disabled="desabilitarMonitoravelCentral"     
                                                class="v-select">
                                            </v-select>                                           
                                        </vx-tooltip>
                                    </div>
                            </div>
                            <h6 class="mt-4 mb-2"> Emails e Contatos de Alertas </h6>
                            <div class="vx-row">
                                    <div class="vx-col w-1/2 flex">    
                                        <vs-input class="w-1/2" placeholder="Nome contato 1" v-model="dadosAtivacao.veiculo.nomeContatoResponsavel1" maxlength="100"/>
                                        <vs-input class="w-1/2" placeholder="Email 1 para avisos" v-model="dadosAtivacao.veiculo.emailAvisoExcessoVelocidade" maxlength="45" />
                                        <vs-input placeholder="Telefone 1" v-model="dadosAtivacao.veiculo.celularAvisoExcessoVelocidade" v-mask="['(##) #####-####', '(##) ####-####']"/>                                
                                    </div>
                                    <div class="vx-col w-1/2 flex">    
                                        <vs-input class="w-1/2" placeholder="Nome contato 2" v-model="dadosAtivacao.veiculo.nomeContatoResponsavel2" maxlength="100"/>
                                        <vs-input class="w-1/2" placeholder="Email 2 para avisos" v-model="dadosAtivacao.veiculo.emailAvisoSaidaCerca" maxlength="45" />
                                        <vs-input placeholder="Telefone 2" v-model="dadosAtivacao.veiculo.celularAvisoSaidaCerca" v-mask="['(##) #####-####', '(##) ####-####']"/>                                
                                    </div>
                            </div>
                           <h6 class="mt-4 mb-2"> Contatos para Emergência/Pânico </h6>
                            <div class="vx-row mt-2">
                                    <div class="vx-col w-1/2">
                                        <label class="vs-input--label">Procedimento de Tratativa de Alertas </label>
                                        <vs-textarea v-model="dadosAtivacao.veiculo.procedimentoPanico" counter="1000" />
                                    </div>
                                    <div class="vx-col w-1/2">
                                        <div class="vx-row">
                                            <div class="vx-col w-full flex">
                                                <vs-input class="w-full" placeholder="Nome contato 1 pânico" v-model="dadosAtivacao.veiculo.nome1ContatoPanico" maxlength="100"/>
                                                <vs-input placeholder="Senha" v-model="dadosAtivacao.veiculo.senha1ContatoPanico" />
                                                <vs-input class="w-full" placeholder="Telefone" v-model="dadosAtivacao.veiculo.telefone1ContatoPanico" v-mask="['(##) #####-####', '(##) ####-####']"/>
                                            </div>
                                        </div>
                                        <div class="vx-row mt-1">
                                            <div class="vx-col w-full flex">
                                                <vs-input class="w-full" placeholder="Nome contato 2 pânico" v-model="dadosAtivacao.veiculo.nome2ContatoPanico" maxlength="100"/>
                                                <vs-input placeholder="Senha" v-model="dadosAtivacao.veiculo.senha2ContatoPanico" />
                                                <vs-input class="w-full" placeholder="Telefone" v-model="dadosAtivacao.veiculo.telefone2ContatoPanico" v-mask="['(##) #####-####', '(##) ####-####']"/>
                                            </div>
                                        </div>
                                        <div class="vx-row mt-1">
                                            <div class="vx-col flex w-full">
                                                <vs-input class="w-full" placeholder="Nome contato 3 pânico" v-model="dadosAtivacao.veiculo.nome3ContatoPanico" maxlength="100"/>
                                                <vs-input placeholder="Senha" v-model="dadosAtivacao.veiculo.senha3ContatoPanico" />
                                                <vs-input class="w-full" placeholder="Telefone" v-model="dadosAtivacao.veiculo.telefone3ContatoPanico" v-mask="['(##) #####-####', '(##) ####-####']"/>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </vx-card>
                    </vs-tab>                                        
                 </vs-tabs>            
            </tab-content>
        </form-wizard>
    </div>
        
  </div>
</template>

<script>
import axios from "@/axios.js"
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { CONSTANTE_TIPO_VEICULO } from '@/constantesImagens.js'
import { CONSTANTE_TIPO_CONTRATO_VEICULO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPOS_SENSOR_RS232 } from '@/constantesComboBox.js'
import { CONSTANTE_TIPOS_AVISO_AUX } from '@/constantesComboBox.js'
import { CONSTANTE_ORIGEM_DADOS } from '@/constantesComboBox.js'
import { CONSTANTE_OPCAO_SIM_NAO } from '@/constantesComboBox.js'


export default {   
    components: {
        FormWizard,
        TabContent,
        VueAutonumeric
    }, 
    created() {        
        this.listaTiposVeiculos = CONSTANTE_TIPO_VEICULO;  
        this.CONSTANTE_TIPO_CONTRATO_VEICULO = CONSTANTE_TIPO_CONTRATO_VEICULO;
        this.CONSTANTE_TIPOS_SENSOR_RS232 = CONSTANTE_TIPOS_SENSOR_RS232;
        this.CONSTANTE_TIPOS_AVISO_AUX = CONSTANTE_TIPOS_AVISO_AUX;
        this.CONSTANTE_ORIGEM_DADOS = CONSTANTE_ORIGEM_DADOS;
        this.listaOpcoesSIM_NAO = CONSTANTE_OPCAO_SIM_NAO;
    },
    computed: {
        getTituloTab1() {
            if (this.listaVeiculoSelecionados && this.listaVeiculoSelecionados.length > 0) {
                return this.listaVeiculoSelecionados.length + " veículos selecionados ";
            } else {
                return "1.Selecione os veículos.";
            }
        } ,
        habilitarEdicaoMensalidades() {
            let perfilLogado = this.$store.state.AppActiveUser.perfil;
            if (perfilLogado === "ATIVADOR" || perfilLogado === "CONFIGURADOR" || perfilLogado === "SUPERVISOR" || perfilLogado === "FINANCEIRO" 
            || perfilLogado === "MONITOR_PANICO" || perfilLogado === "EMPRESA_MONITOR_PANICO" || perfilLogado === "EMPRESA_SUPERVISOR" || perfilLogado === "EMPRESA_ATIVADOR") {
                return false;
            }

            return true;
        },
        desabilitarMonitoravelCentral() {
            if (this.dadosAtivacao && this.dadosAtivacao.clienteSelecionado && this.dadosAtivacao.clienteSelecionado.monitoravelPorCentral) {
                return false;
            }

            return true;
        },
        getTextoMonitoravelCentral(){
            if (this.dadosAtivacao && this.dadosAtivacao.clienteSelecionado && this.dadosAtivacao.clienteSelecionado.monitoravelPorCentral) {
                return "Exibir os avisos desse veículo em Backend -> Monitoramento ";
            }

            return "O cliente não está com o Monitoramento habilitado em Cadastros --> Clientes";
        },
        qtdRegistros() {
            return this.$refs.table ? this.$refs.table.queriedResults.length : this.listaVeiculos.length
        },
        filteredVeiculos: function () {
            var self = this;
            var arrayRetorno = self.listaVeiculos;  

            arrayRetorno = arrayRetorno.filter(function (veiculo) {
                 let termoUC = ( self.termoPesquisa != null ? self.termoPesquisa.toUpperCase() : null);
                 return (termoUC ? (veiculo.modelo.toUpperCase().indexOf(termoUC) != -1 ||                            
                                    veiculo.condutor.toUpperCase().indexOf(termoUC) != -1 || 
                                    (veiculo.labelVeiculo && veiculo.labelVeiculo.toUpperCase().indexOf(termoUC) != -1)|| 
                                    veiculo.placa.toUpperCase().indexOf(termoUC) != -1): true)
            });

            if (self.divisaoSelecionada != null) {
                arrayRetorno = arrayRetorno.filter(function (veiculo) {
                    return veiculo.hasOwnProperty('idDivisao') && self.divisaoSelecionada.id === veiculo.idDivisao;
                })
                
            }

            return arrayRetorno;
        },
    },
    data() {
        return {   
            txtTermoPesquisa: null,
            termoPesquisa: null,
            listaDivisoesVeiculo: [],
            divisaoSelecionada: null,         
            dadosAtivacao: {                
                veiculo: { tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, 
                    autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, 
                    possuiBotaoPanico: false, possuiBloqueio: false, possuiCentralMonitoramento: false, procedimentoPanico: null, 
                    emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null, 
                    celularAvisoExcessoVelocidade: null, celularlAvisoSaidaCerca: null,
                    nomeContatoResponsavel1: null,  nomeContatoResponsavel2: null,   emManutencao: null, 
                    tempoSemConexaoParaAlerta: null, tempoSemConexaoIgnicaoONParaAlerta: null, ativarEspelhamento: null, 
                    taxaAtualizacao: null, taxaAtualizacaoRPM: null, origemDadosOdometro: null, origemDadosHorimetro: null, 
                    corVeiculo: null, possuiMonitriip: null, manterMotoristaVeiculoDesligado: null, 
                    nome1ContatoPanico: null, telefone1ContatoPanico: null,
                    nome2ContatoPanico: null, telefone2ContatoPanico: null,
                    nome3ContatoPanico: null, telefone3ContatoPanico: null                    
                },
                clienteSelecionado: null,                
                dadosAdicionaisClienteSelecionado: null,
            },
            listaClientes: [],
            listaVeiculos: [],              
            listaMarcaVeiculos: [], 
            listaModeloVeiculos: [], 
            exibirMensagemNenhumDadoEncontrado: false,   
            listaVeiculoSelecionados: [],       
            configuracaoPopUpMotoristas: {
                condutorOriginal: null, 
                exibirPopUpMotorista: false,
                motoristasDoPopUp: null,              
                idClienteSelecionado: null,
                mensagemExplicacao: ""
            },    
            idNovoMotorista: null,      
            toggleBtnEmail: 'SIM',
            toggleBtnSMS: 'SIM',
            toggleBtnCentral: 'SIM',
            toggleBtnPush: 'SIM',  
            listaAcessorios: [],
            popUpAcessorio: {
                exibir: false, 
                valorMensalidadeAcessorio: 0,
            },
            tabSelecionado: 0,
            errorsValidacao: [],  
            publicPath: process.env.BASE_URL
        }
    },
    methods: { 
        pesquisarPorTermo(valorTermo){
            this.termoPesquisa = valorTermo;                      
        },
        fetchListaDivisaoVeiculos() {
            const params = new URLSearchParams();
            params.append('idCliente', this.dadosAtivacao.clienteSelecionado.id);

            axios.post("/rest/DivisaoVeiculo/Listar", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {                    
                if (response.data.Resultado.quantidade > 1) {
                  this.listaDivisoesVeiculo = response.data.Resultado.lista.item;
                } else if (response.data.Resultado.quantidade === 1) {
                    this.listaDivisoesVeiculo = [];
                    this.listaDivisoesVeiculo.push(response.data.Resultado.lista.item);
                } else {
                    this.listaDivisoesVeiculo = [];
                }    
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("Error Fetch Lista Marcas Veiculos " + error);        
            })      
        },
        getModelo(tr) {
            if (tr.modelo === 'Outro') {
                return tr.modeloOutro;
            } else {
                return tr.modelo;
            }
        },        
        selecionarCliente(cliente) {
            if (cliente) {
                this.fetchListaVeiculos();
                this.fetchListaDivisaoVeiculos();
            } else {
                this.dadosAtivacao.veiculo= { tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, 
                    autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, 
                    possuiBotaoPanico: false, possuiBloqueio: false, possuiCentralMonitoramento: false, procedimentoPanico: null, 
                    emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null, 
                    celularAvisoExcessoVelocidade: null, celularlAvisoSaidaCerca: null,
                    nomeContatoResponsavel1: null,  nomeContatoResponsavel2: null,   emManutencao: null, 
                    tempoSemConexaoParaAlerta: null, tempoSemConexaoIgnicaoONParaAlerta:null, ativarEspelhamento: null, 
                    taxaAtualizacao: null, taxaAtualizacaoRPM: null, origemDadosOdometro: null, origemDadosHorimetro: null, 
                    corVeiculo: null, possuiMonitriip: null, manterMotoristaVeiculoDesligado: null, 
                    nome1ContatoPanico: null, telefone1ContatoPanico: null,
                    nome2ContatoPanico: null, telefone2ContatoPanico: null,
                    nome3ContatoPanico: null, telefone3ContatoPanico: null    };

                this.dadosAtivacao.clienteSelecionado = null;
                this.dadosAtivacao.dadosAdicionaisClienteSelecionado = null;
                this.veiculoSelecionado = null;
                this.tabSelecionado = 0;
                this.listaVeiculos = [];
            }
        },        
        exibirMenssageErro: function(errorMsg){
            if (errorMsg) {
                this.$vs.notify({
                    title: "Erro", text: errorMsg, time: 10000,
                    iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
            }
        },
        validarPasso1() {
            let self_ = this;

            return new Promise((resolve, reject) => {
                if (self_.listaVeiculoSelecionados && self_.listaVeiculoSelecionados.length > 0) {                    
                    return resolve(true);
                }
                return reject("Selecione pelo menos um veículo");
            });
        },          
        ativar() {
            if (!this.formularioValido()) {
                return;
            }
            let self_ = this;
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja alterar os dados de ' + self_.listaVeiculoSelecionados.length 
                + ' veículos ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.ativarRequest
            });
        },
        ativarRequest() {
            this.$vs.loading();  
            const params = new URLSearchParams();  
            params.append('outputFormat', 'JSON');
            params.append('acao', 'ALTERAR_VEICULOS_EM_LOTE');            
            params.append('idCliente', this.dadosAtivacao.clienteSelecionado.id);   

            var idsVeiculos = null;
            var placasVeiculos = null;

            for (var i= 0; i < this.listaVeiculoSelecionados.length; i++){
                if (idsVeiculos === null) {
                    idsVeiculos = new String();
                    placasVeiculos = new String();
                    idsVeiculos = idsVeiculos + this.listaVeiculoSelecionados[i].idVeiculo;
                    placasVeiculos = placasVeiculos + this.listaVeiculoSelecionados[i].placa;
                } else {
                    idsVeiculos = idsVeiculos + "," + this.listaVeiculoSelecionados[i].idVeiculo;
                    placasVeiculos = placasVeiculos + "," + this.listaVeiculoSelecionados[i].placa;
                }
            }

            params.append('idsVeiculos', idsVeiculos); 
            params.append('placasVeiculos', placasVeiculos); 
            params.append('cnpjCPF', this.dadosAtivacao.clienteSelecionado.CNPJCPF);
            if (this.dadosAtivacao.veiculo.tipoVeiculo) {
                params.append('tipoVeiculo', this.dadosAtivacao.veiculo.tipoVeiculo);   
            }
            if (this.dadosAtivacao.veiculo.marca) {
                params.append('marcaVeiculo', this.dadosAtivacao.veiculo.marca);   
            }
            if (this.dadosAtivacao.veiculo.modelo) {
                params.append('modeloVeiculo', this.dadosAtivacao.veiculo.modelo);   
            }            
            if (this.dadosAtivacao.veiculo.autonomia) {
                params.append('autonomia', this.dadosAtivacao.veiculo.autonomia);   
            }
            if (this.dadosAtivacao.veiculo.autonomiaPorHora) {
                params.append('autonomiaPorHora', this.dadosAtivacao.veiculo.autonomiaPorHora);                           
            }
            if (this.dadosAtivacao.veiculo.possuiBotaoPanico) {
                params.append('possuiBotaoPanico', (this.dadosAtivacao.veiculo.possuiBotaoPanico === 'SIM' ? true : false) );
            }
            if (this.dadosAtivacao.veiculo.possuiBloqueio) {
                params.append('possuiBloqueio', ( this.dadosAtivacao.veiculo.possuiBloqueio  === 'SIM' ? true : false));    
            }
            if (this.dadosAtivacao.veiculo.possuiCentralMonitoramento) {
                params.append('possuiCentralMonitoramento', ( this.dadosAtivacao.veiculo.possuiCentralMonitoramento === 'SIM' ? true : false));
            }
            if (this.dadosAtivacao.veiculo.procedimentoPanico) { 
                params.append('procedimentoPanico', this.dadosAtivacao.veiculo.procedimentoPanico);  
            }            
            if (this.dadosAtivacao.veiculo.emailAvisoExcessoVelocidade) {
                params.append('emailAvisoExcessoVelocidade', this.dadosAtivacao.veiculo.emailAvisoExcessoVelocidade);   
            }            
            if (this.dadosAtivacao.veiculo.emailAvisoSaidaCerca) {
                params.append('emailAvisoSaidaCerca', this.dadosAtivacao.veiculo.emailAvisoSaidaCerca);   
            }
            if (this.dadosAtivacao.veiculo.celularAvisoExcessoVelocidade) {
                params.append('celularAvisoExcessoVelocidade', this.dadosAtivacao.veiculo.celularAvisoExcessoVelocidade);   
            }   
            if (this.dadosAtivacao.veiculo.celularAvisoSaidaCerca) {
                params.append('celularAvisoSaidaCerca', this.dadosAtivacao.veiculo.celularAvisoSaidaCerca);   
            }   

            if (this.dadosAtivacao.veiculo.tempoSemConexaoParaAlerta){
                params.append('tempoSemConexaoParaAlerta', this.dadosAtivacao.veiculo.tempoSemConexaoParaAlerta);
            }
            if (this.dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta){
                params.append('tempoSemConexaoIgnicaoONParaAlerta', this.dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta);
            }
            if (this.dadosAtivacao.veiculo.ativarEspelhamento) {
                params.append('ativarEspelhamento', ( this.dadosAtivacao.veiculo.ativarEspelhamento === 'SIM' ? true : false));
            }            
            if (this.dadosAtivacao.veiculo.taxaAtualizacao) {
                params.append('taxaAtualizacaoPorMinuto', this.dadosAtivacao.veiculo.taxaAtualizacao);
            }
            if (this.dadosAtivacao.veiculo.taxaAtualizacaoRPM) {
                params.append('taxaAtualizacaoPorRPMMinuto', this.dadosAtivacao.veiculo.taxaAtualizacaoRPM);
            }
            if (this.dadosAtivacao.veiculo.origemDadosOdometro) {
                params.append('origemDadosOdometro', this.dadosAtivacao.veiculo.origemDadosOdometro);
            }
            if (this.dadosAtivacao.veiculo.origemDadosHorimetro) {
                params.append('origemDadosHorimetro', this.dadosAtivacao.veiculo.origemDadosHorimetro);
            }
            
            if (this.dadosAtivacao.veiculo.tipoContratoVeiculo) {
                params.append('tipoContratoVeiculo', this.dadosAtivacao.veiculo.tipoContratoVeiculo);
            }
            if (this.dadosAtivacao.veiculo.prazoContratoVeiculoEmMeses) {
                params.append('prazoContratoVeiculoEmMeses', this.dadosAtivacao.veiculo.prazoContratoVeiculoEmMeses);
            }
            
            if (this.dadosAtivacao.veiculo.corVeiculo) {
                params.append('corVeiculo', this.dadosAtivacao.veiculo.corVeiculo);
            }
            if (this.dadosAtivacao.veiculo.possuiMonitriip) {
                params.append('possuiMonitriip', ( this.dadosAtivacao.veiculo.possuiMonitriip === 'SIM' ? true : false))
            }           
            if (this.dadosAtivacao.veiculo.manterMotoristaVeiculoDesligado) {
                params.append('manterMotoristaVeiculoDesligado', ( this.dadosAtivacao.veiculo.manterMotoristaVeiculoDesligado === 'SIM' ? true : false));
            }
            if (this.dadosAtivacao.veiculo.nomeContatoResponsavel1) {
                params.append('nomeContatoResponsavel1', this.dadosAtivacao.veiculo.nomeContatoResponsavel1);
            }
            if (this.dadosAtivacao.veiculo.nomeContatoResponsavel2) {
                params.append('nomeContatoResponsavel2', this.dadosAtivacao.veiculo.nomeContatoResponsavel2);
            }            
            if (this.dadosAtivacao.veiculo.emManutencao) {
                params.append('emManutencao', (this.dadosAtivacao.veiculo.emManutencao === 'SIM' ? true : false));
            }
            if (this.dadosAtivacao.veiculo.mensalidade) {
                params.append('mensalidade', this.dadosAtivacao.veiculo.mensalidade);   
            }
            if (this.dadosAtivacao.veiculo.nome1ContatoPanico) { params.append('nome1ContatoPanico', this.dadosAtivacao.veiculo.nome1ContatoPanico); }
            if (this.dadosAtivacao.veiculo.nome2ContatoPanico) { params.append('nome2ContatoPanico', this.dadosAtivacao.veiculo.nome2ContatoPanico); }
            if (this.dadosAtivacao.veiculo.nome3ContatoPanico) { params.append('nome3ContatoPanico', this.dadosAtivacao.veiculo.nome3ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone1ContatoPanico) { params.append('telefone1ContatoPanico', this.dadosAtivacao.veiculo.telefone1ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone2ContatoPanico) { params.append('telefone2ContatoPanico', this.dadosAtivacao.veiculo.telefone2ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone3ContatoPanico) { params.append('telefone3ContatoPanico', this.dadosAtivacao.veiculo.telefone3ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone1ContatoPanico) { params.append('senha1ContatoPanico', this.dadosAtivacao.veiculo.senha1ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone2ContatoPanico) { params.append('senha2ContatoPanico', this.dadosAtivacao.veiculo.senha2ContatoPanico); }
            if (this.dadosAtivacao.veiculo.telefone3ContatoPanico) { params.append('senha3ContatoPanico', this.dadosAtivacao.veiculo.senha3ContatoPanico); }

            axios.post("/AlterarVeiculo", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    if (response.data.mensagem.toLowerCase().indexOf('erro') !== -1) {
                        this.$vs.notify({
                                title: "Mensagem", text: response.data.mensagem, time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
                    } else {
                        this.$vs.notify({
                                title: "Mensagem", text: response.data.mensagem, time: 10000,
                                iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                        this.$refs.fwAtivacao.reset();
                        this.dadosAtivacao.veiculo = {  tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, 
                                                        autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, 
                                                        possuiBotaoPanico: false, possuiBloqueio: false, possuiCentralMonitoramento: false, procedimentoPanico: null, 
                                                        emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null, 
                                                        celularAvisoExcessoVelocidade: null, celularlAvisoSaidaCerca: null,
                                                        nomeContatoResponsavel1: null,  nomeContatoResponsavel2: null,   emManutencao: null, 
                                                        tempoSemConexaoParaAlerta: null, tempoSemConexaoIgnicaoONParaAlerta:null, ativarEspelhamento: null, 
                                                        taxaAtualizacao: null, taxaAtualizacaoRPM: null, origemDadosOdometro: null, origemDadosHorimetro: null, 
                                                        corVeiculo: null, possuiMonitriip: null, manterMotoristaVeiculoDesligado: null, 
                                                        nome1ContatoPanico: null, telefone1ContatoPanico: null,
                                                        nome2ContatoPanico: null, telefone2ContatoPanico: null,
                                                        nome3ContatoPanico: null, telefone3ContatoPanico: null  };
                        this.veiculoSelecionado = null;
                        this.fetchListaVeiculos();
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error);                 
            })  
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },        
        fetchListaVeiculos() {     
            if (!this.dadosAtivacao.clienteSelecionado) {
                return;
            } else {
                if (this.veiculoSelecionado && this.veiculoSelecionado.id) {
                    this.$refs.fwAtivacao.reset();
                    this.dadosAtivacao.veiculo = {  tipoVeiculo: null, marca: null, modelo:null, modeloOutro: null, placa: null, condutor: null, 
                                                    autonomia: null, autonomiaPorHora: null, mensalidade: null, tipoContratoVeiculo: null, prazoContratoVeiculoEmMeses: null, 
                                                    possuiBotaoPanico: false, possuiBloqueio: false, possuiCentralMonitoramento: false, procedimentoPanico: null, 
                                                    emailAvisoExcessoVelocidade: null, emailAvisoSaidaCerca: null, 
                                                    celularAvisoExcessoVelocidade: null, celularlAvisoSaidaCerca: null,
                                                    nomeContatoResponsavel1: null,  nomeContatoResponsavel2: null,   emManutencao: null, 
                                                    tempoSemConexaoParaAlerta: null, tempoSemConexaoIgnicaoONParaAlerta:null, ativarEspelhamento: null, 
                                                    taxaAtualizacao: null, taxaAtualizacaoRPM: null, origemDadosOdometro: null, origemDadosHorimetro: null, 
                                                    corVeiculo: null, possuiMonitriip: null, manterMotoristaVeiculoDesligado: null, 
                                                    nome1ContatoPanico: null, telefone1ContatoPanico: null,
                                                    nome2ContatoPanico: null, telefone2ContatoPanico: null,
                                                    nome3ContatoPanico: null, telefone3ContatoPanico: null  };
                    this.veiculoSelecionado = null;
                    this.listaVeiculos = [];
                }
            }
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');            
            params.append('cpfcnpj', this.dadosAtivacao.clienteSelecionado.CNPJCPF);
            
            axios.post("/RecuperarClientePorCNPJouCPFComVeiculosAtivos", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaVeiculos = response.data.listaVeiculos;  
                    this.dadosAtivacao.dadosAdicionaisClienteSelecionado = response.data.dadosCliente;
                    this.exibirMensagemNenhumDadoEncontrado = this.listaVeiculos.length === 0;

                    if (this.listaVeiculos.length > 0 && this.dadosAtivacao.veiculo && this.dadosAtivacao.veiculo.placa) {
                        this.veiculoSelecionado = this.dadosAtivacao.veiculo;
                    } else {
                        this.veiculoSelecionado = null;
                    }
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');
            
            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;                            
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        fetchListaMarcaVeiculos() {
            axios.get("/rest/MarcaVeiculo/Listar", null, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {                    
                this.listaMarcaVeiculos = response.data.Resultado.lista.item;
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("Error Fetch Lista Marcas Veiculos " + error);        
            })      
        },  
        fetchListaModeloVeiculos() {
            const params = new URLSearchParams();
            params.append('pagina', -1);
            axios.post("/rest/ModeloVeiculo/Listar", params, { 
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {                    
                this.listaModeloVeiculos = response.data.Resultado.lista.item;
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("Error Fech Lista Modelo Veiculos " + error);        
            })      
        },         
        valorValido(valor){
           if (!valor && valor !== 0) {
               return false;
           }
            
            return true
        },
        formularioValido() {
            let algumDadoPreenchido = false;
            this.$refs.errosComponent.iniciar();
            this.errorsValidacao = [];

            if (this.dadosAtivacao.veiculo.tipoVeiculo) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.marca) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.modelo) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.modelo === 'Outro' && this.dadosAtivacao.veiculo.modeloOutro) {  algumDadoPreenchido = true; }                        
            if (this.dadosAtivacao.veiculo.corVeiculo) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.autonomia) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.autonomiaPorHora) { algumDadoPreenchido = true; }    
            
            if (this.dadosAtivacao.veiculo.tempoSemConexaoParaAlerta) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.taxaAtualizacao) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.taxaAtualizacaoRPM) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.origemDadosOdometro) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.origemDadosHorimetro) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.possuiMonitriip) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.possuiCentralMonitoramento) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.emManutencao) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.manterMotoristaVeiculoDesligado) { algumDadoPreenchido = true; }
            
            if (this.dadosAtivacao.veiculo.tipoContratoVeiculo) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.prazoContratoVeiculoEmMeses) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.mensalidade) { algumDadoPreenchido = true; }

            if (this.dadosAtivacao.veiculo.marcaModuloBkp && !this.dadosAtivacao.veiculo.mensalidadeBkp) { this.errorsValidacao.push("Mensalidade de Backup obrigatório"); }
            if (this.dadosAtivacao.veiculo.emailAvisoExcessoVelocidade) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.emailAvisoSaidaCerca) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.possuiBotaoPanico) { algumDadoPreenchido = true; }
            
            if (this.dadosAtivacao.veiculo.nomeContatoResponsavel1) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.nomeContatoResponsavel2) { algumDadoPreenchido = true; }

            if (this.dadosAtivacao.veiculo.celularlAvisoExcessoVelocidade) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.celularAvisoSaidaCerca) { algumDadoPreenchido = true; }

            if (this.dadosAtivacao.veiculo.procedimentoPanico) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.nome1ContatoPanico) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.nome2ContatoPanico) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.nome3ContatoPanico) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.senha1ContatoPanico) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.senha2ContatoPanico) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.senha3ContatoPanico) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.telefone1ContatoPanico) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.telefone2ContatoPanico) { algumDadoPreenchido = true; }
            if (this.dadosAtivacao.veiculo.telefone3ContatoPanico) { algumDadoPreenchido = true; }

            if (this.dadosAtivacao.veiculo.ativarEspelhamento) { algumDadoPreenchido = true; }

             if (this.dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta) {
                if (!this.dadosAtivacao.veiculo.tempoSemConexaoParaAlerta) {
                    this.errorsValidacao.push("Informe o Alerta Sem Conexão.");
                } else if (this.dadosAtivacao.veiculo.tempoSemConexaoIgnicaoONParaAlerta > this.dadosAtivacao.veiculo.tempoSemConexaoParaAlerta) {
                    this.errorsValidacao.push("Informe o Alerta Sem Conexão maior que o Alerta Sem Conexão Ligado.");
                }
            }
            

            if (algumDadoPreenchido) {
                if (!this.errorsValidacao.length) {
                    return true;
                }  
                return false;
            }  else {
                this.errorsValidacao.push("Informe pelo menos algum dado para alterar em lote.");
            }  
        
            return false;
        }        
    },
    mounted() {               
        this.fetchListaClientes();
        this.fetchListaMarcaVeiculos();
        this.fetchListaModeloVeiculos();
    }    
}
</script>

<style>  
  .style-chooser .vs__dropdown-menu {
    max-height: 150px;
  }
</style>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 410px);
      position: relative;      
   } 
   .tabelaNotificacoesComScroll {      
      overflow: auto;  
      height: calc(100vh - 410px);
      position: relative;      
  }
</style>